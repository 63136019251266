<template>
  <!-- Header Start -->
  <header class="site-header">
    <div class="site-header__wrapper">
      <a href="#" class="brand">
        <h1>Azure Cloud Adoption Framework</h1>
        <h2>Patterns & Practices</h2>
      </a>
      <nav class="nav">
        <button class="nav__toggle" aria-expanded="false" type="button">
          menu
        </button>
        <ul class="nav__wrapper">
          <li class="nav__item"><a href="#">Naming Guidelines</a></li>
          <li class="nav__item"><a href="#">Tagging</a></li>
          <li class="nav__item"><a href="#">About</a></li>
        </ul>
      </nav>
    </div>
  </header>
  <!-- Header End -->
</template>
